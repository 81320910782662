import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent {
    @Output() toggle = new EventEmitter<boolean>();
    isSidebarClosed = false;
  
    userEmail: string | null = null;
    constructor(
      private authService: AuthService,
      private router: Router,
      private toastr: ToastrService
    ) {}
  
    ngOnInit(): void {
      this.authService.getUserEmail().subscribe(email => {
        this.userEmail = email;
      });
    }
  
    toggleSidebar() {
      this.isSidebarClosed = !this.isSidebarClosed;
      this.toggle.emit(this.isSidebarClosed);
    }
    logout(): void {
      this.authService.logout().then(() => {
        this.toastr.success('Logged out successfully');
        this.router.navigate(['/login']); // Redirect to the login page
      }).catch(error => {
        this.toastr.error('Failed to log out');
        console.error('Logout error:', error);
      });
    }
}
