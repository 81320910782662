import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  email: string = '';  // Initialize with empty string
  password: string = '';  // Initialize with empty string
  errorMessage: string | null = null;

  constructor(private afAuth: AngularFireAuth, private router: Router) {}

  login() {
    this.afAuth.signInWithEmailAndPassword(this.email, this.password)
      .then(result => {
        this.errorMessage = null;  // Clear any previous error messages
        this.router.navigate(['/dashboard']);  // Redirect to the dashboard
      })
      .catch(error => {
        this.errorMessage = "Incorrect email or password";  // Set error message
      });
  }

  // Sign out
  signOut() {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/sign-in']);  // Redirect to sign-in page after sign out
    });
  }

  // Check if the user is logged in
  isLoggedIn() {
    return this.afAuth.authState;
  }
}
