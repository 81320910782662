<div class="container-fluid">
  <div class="row">
    <app-sidebar></app-sidebar>
    <div class="container mt-4">
      <h2>Bookings</h2>

      <!-- Search and Filter Controls -->
      <div class="row mb-3">
        <div class="col-md-6 col-lg-4 mb-2">
          <input 
            type="text" 
            class="form-control" 
            placeholder="Search by Title or Meeting ID" 
            [(ngModel)]="searchTerm"
            (input)="searchAndFilter()" />
        </div>
        <div class="col-md-6 col-lg-4 mb-2">
          <select class="form-control" [(ngModel)]="selectedFilter" (change)="searchAndFilter()">
            <option value="">All</option>
            <option value="therapist">Confirmed by Therapist</option>
            <option value="client">Confirmed by Client</option>
            <option value="both">Confirmed by Both</option>
            <option value="none">Unconfirmed by Both</option>
          </select>
        </div>
      </div>

      <!-- Bookings Table -->
      <table class="table table-bordered">
        <thead>
          <tr>
            <th (click)="changeSortColumn('title')">
              Title
              <span *ngIf="sortColumn === 'title'">
                {{ sortDirection === 'asc' ? '↑' : '↓' }}
              </span>
            </th>
            <th (click)="changeSortColumn('clientName')">
              Client Name
              <span *ngIf="sortColumn === 'clientName'">
                {{ sortDirection === 'asc' ? '↑' : '↓' }}
              </span>
            </th>
            <th (click)="changeSortColumn('date')">
              Date
              <span *ngIf="sortColumn === 'date'">
                {{ sortDirection === 'asc' ? '↑' : '↓' }}
              </span>
            </th>
            <th (click)="changeSortColumn('duration')">
              Duration
              <span *ngIf="sortColumn === 'duration'">
                {{ sortDirection === 'asc' ? '↑' : '↓' }}
              </span>
            </th>
            <th (click)="changeSortColumn('id')">
              Meeting ID
              <span *ngIf="sortColumn === 'id'">
                {{ sortDirection === 'asc' ? '↑' : '↓' }}
              </span>
            </th>
            <th (click)="changeSortColumn('therapist')">
              Therapist
              <span *ngIf="sortColumn === 'therapist'">
                {{ sortDirection === 'asc' ? '↑' : '↓' }}
              </span>
            </th>
            <th (click)="changeSortColumn('client')">
              Client
              <span *ngIf="sortColumn === 'client'">
                {{ sortDirection === 'asc' ? '↑' : '↓' }}
              </span>
            </th>
            <th (click)="changeSortColumn('feedback')">
              Feedback
              <span *ngIf="sortColumn === 'feedback'">
                {{ sortDirection === 'asc' ? '↑' : '↓' }}
              </span>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        
        <tbody>
          <tr *ngFor="let booking of paginatedBookings">
            <td>{{ booking?.title || 'N/A' }}</td>
            <td>{{ getClientName(booking) }}</td>
            <td>{{ booking?.date ? (booking.date | date: 'shortDate') : 'N/A' }}</td>
            <td>{{ getDuration(booking) }}</td>
            <td>{{ booking?.id || 'N/A' }}</td> 
            <td [ngClass]="{
                'confirmed-status': getTherapistConfirmation(booking) === 'Confirmed',
                'unconfirmed-status': getTherapistConfirmation(booking) === 'Not Confirmed'
            }">
              {{ getTherapistConfirmation(booking) }}
            </td>
            <td [ngClass]="{
                'confirmed-status': getClientConfirmation(booking) === 'Confirmed',
                'unconfirmed-status': getClientConfirmation(booking) === 'Not Confirmed'  
            }">
              {{ getClientConfirmation(booking) }}
            </td>
            <td>{{ getFeedback(booking) }}</td> <!-- Display Feedback -->
            <td>
              <button (click)="viewDetails(booking)" class="btn btn-purple">View</button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination Controls -->
      <div class="pagination-controls">
        <button (click)="prevPage()" [disabled]="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages() }}</span>
        <button (click)="nextPage()" [disabled]="currentPage === totalPages()">Next</button>
      </div>
    </div>
  </div>
</div>
