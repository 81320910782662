<div class="row mb-3">
    <!-- Total Users Card -->
    <div class="col-md-3">
        <div class="card shadow-sm text-center card-sales" style="box-shadow: 4px 8px rgba(101, 82, 197, 0.733);">
            <div class="card-body" style="max-height: 180px;">
                <i class="bi bi-people icon" style="display: flex; color: #6610f2; font-size: 24px;"></i>
                <p style="font-weight:bold" class="card-text">Total Users</p>
                <p style="font-weight: 800;" class="card-text display-4">{{totalUsers}}</p>
                <hr class="purple-line" style="margin:0;">
                <p style="font-weight: 100;" class="card-text">Total Active Users</p>
                <p style="font-weight: 800;" class="card-text display-4">{{ totalActiveUsers}}</p>
            </div>
        </div>
    </div>
    
    <!-- Total Bookings Card -->
    <div class="col-md-3">
        <div class="card shadow-sm text-center card-sales" style="box-shadow: 4px 8px rgba(101, 82, 197, 0.733);">
            <div class="card-body">
                <i class="bi bi-calendar icon" style="display: flex; color: #6610f2; font-size: 24px;"></i>
                <p style="font-weight: bold;" class="card-text">Total Bookings</p>
                <p style="font-weight: 800;" class="card-text display-4">{{totalAppointments}}</p>
                <hr class="purple-line" style="margin:0;">
                <p style="font-weight: 100;" class="card-text">Completed Bookings</p>
                <p style="font-weight: 800;" class="card-text display-4">{{ totalCompletedSessions }}</p>
            </div>
        </div>
    </div>
    
    <!-- Feedback Card -->
    <div class="col-md-3">
        <div class="card shadow-sm text-center card-sales" style="box-shadow: 4px 8px rgba(101, 82, 197, 0.733);" (click)="openFeedbackModal()">
            <div class="card-body" style="min-height: 180px;" >
                <i class="bi bi-star icon" style="display: flex; color: #6610f2; font-size: 24px;"></i>
                <p style="font-weight: bold;" class="card-text">Feedback Rating</p>
                <div class="feedback-rating">
                    <ng-container *ngFor="let star of stars">
                        <i class="bi bi-star" [ngClass]="{'text': star <= totalFeedbackRating, 'text-secondary': star > totalFeedbackRating}"></i>
                    </ng-container>
                </div>
                <hr class="purple-line" style="margin-top: 6px;">
                <button class="btn btn-feedback" (click)="openFeedbackList($event)" style="margin-top: 5px;">View More</button>
            </div>
        </div>
    </div>
    <!-- Feedback Modal -->
<ng-template #feedbackModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Feedback List</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
      <!-- Show loading spinner when data is loading -->
      <div *ngIf="loading" class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
  
      <!-- Show feedback items when data is loaded -->
      <div *ngIf="!loading">
        <div *ngFor="let feedback of paginatedFeedbackList">
            <div class="feedback-item mb-3 p-3 border rounded">  
                <p><strong>Rating:</strong> 
                    <ng-container *ngFor="let star of stars">
                        <i class="bi bi-star" style="color: #edd500;" [ngClass]="{'text': star <= totalFeedbackRating, 'text-secondary': star > feedback.rating}"></i>
                    </ng-container>
                </p>
                <p><strong>Focus Areas:</strong> {{ feedback.focus_areas.join(', ') || 'None' }}</p>
                <p *ngIf="feedback.message"><strong>Message:</strong> {{ feedback.message }}</p>
                <p><strong>Created At:</strong> {{ feedback.created_at | date:'short' }}</p>
              </div>
              
        </div>
  
        <!-- Pagination Controls at the bottom -->
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item" [class.disabled]="currentPage === 1">
              <a class="page-link" (click)="changePage(currentPage - 1)">Previous</a>
            </li>
            <li class="page-item" *ngFor="let page of pageNumbers">
              <a class="page-link" [class.active]="page === currentPage" (click)="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages">
              <a class="page-link" (click)="changePage(currentPage + 1)">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </ng-template>
  


    <!-- Total Payment Receipts Card -->
    <div class="col-md-3">
        <div class="card shadow-sm text-center card-sales" style="box-shadow: 4px 8px rgba(101, 82, 197, 0.733);">
            <div class="card-body" style="max-height: 180px;">
                <i class="bi bi-file-earmark-text icon" style="display: flex; color: #6610f2; font-size: 24px;"></i>
                <p style="font-weight: bold;" class="card-text ">Payment Receipts</p>
                <hr class="purple-line" style="margin-top:38px;">
                <p style="font-weight: 100;" class="card-text" >Total  Receipts</p>
                <p style="font-weight: 800;" class="card-text display-4">{{ totalReceipts }}</p>
            </div>
        </div>
    </div>
</div>
