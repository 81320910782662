<ng-template #feedbackModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">User Feedback</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let feedback of feedbackList">
          {{ feedback }}
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Close</button>
    </div>
  </ng-template>
  