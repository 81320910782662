<div class="container-fluid">
    <div class="row">
      <app-sidebar></app-sidebar>
<main class="col">
        <div class="container">
            <h5 class="card-title">Receipts chart</h5>
                <div class="card shadow-sm">
                  <div class="card-body">
                   
                    <div class="row">
                      <div class="col-md-6">
                        <label for="startDate">Start Date:</label>
                        <input type="date" id="startDate" [(ngModel)]="startDate" (change)="onDateChange()">
                      </div>
                      <div class="col-md-6">
                        <label for="endDate">End Date:</label>
                        <input type="date" id="endDate" [(ngModel)]="endDate" (change)="onDateChange()">
                      </div>
                    </div>
                    <canvas id="receiptschart"></canvas>
                  </div>
                </div>
          </div>
          <!-- Search Input -->
          <div class="container" style="margin-top: 30px;">
            <h6 class="my-4 text-center">Payment Receipts</h6>
            <div class="col-mb-6" style="margin-bottom: 30px;">
            <input
              type="text"
              class="form-control"
              placeholder="Search by name, transaction ID, or product"
              [(ngModel)]="searchTerm"
              (input)="onSearchChange()"
            />
          </div>
    
          <!-- Table -->
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th (click)="sortData('userName')">
                  Name
                  <i
                    class="bi"
                    [ngClass]="{
                      'bi-sort-alpha-down': sortColumn === 'userName' && sortOrder === 'asc',
                      'bi-sort-alpha-up': sortColumn === 'userName' && sortOrder === 'desc'
                    }"
                  ></i>
                </th>
                <th (click)="sortData('transaction_identifier')">
                  Transaction ID
                  <i
                    class="bi"
                    [ngClass]="{
                      'bi-sort-alpha-down': sortColumn === 'transaction_identifier' && sortOrder === 'asc',
                      'bi-sort-alpha-up': sortColumn === 'transaction_identifier' && sortOrder === 'desc'
                    }"
                  ></i>
                </th>
                <th (click)="sortData('purchase_date')">
                  Purchase Date
                  <i
                    class="bi"
                    [ngClass]="{
                      'bi-sort-alpha-down': sortColumn === 'purchase_date' && sortOrder === 'asc',
                      'bi-sort-alpha-up': sortColumn === 'purchase_date' && sortOrder === 'desc'
                    }"
                  ></i>
                </th>
                <th (click)="sortData('product_identifier')">
                  Product
                  <i
                    class="bi"
                    [ngClass]="{
                      'bi-sort-alpha-down': sortColumn === 'product_identifier' && sortOrder === 'asc',
                      'bi-sort-alpha-up': sortColumn === 'product_identifier' && sortOrder === 'desc'
                    }"
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let receipt of sortedReceipts">
                <td>{{ receipt.user.name }}</td>
                <td>{{ receipt.transaction_identifier }}</td>
                <td>{{ receipt.purchase_date | date: 'MMMM d, y, h:mm a' }}</td>
                <td>{{ receipt.product_identifier }}</td>
              </tr>
            </tbody>
          </table>
        </div>
          <!-- Pagination Controls -->
          <nav>
            <ul class="pagination">
              <li class="page-item" [class.disabled]="currentPage === 1">
                <a class="page-link" (click)="onPageChange(currentPage - 1)">Previous</a>
              </li>
              <li
                class="page-item"
                *ngFor="let page of [].constructor(getTotalPages()); let i = index"
                [class.active]="currentPage === i + 1"
              >
                <a class="page-link" (click)="onPageChange(i + 1)">{{ i + 1 }}</a>
              </li>
              <li class="page-item" [class.disabled]="currentPage === getTotalPages()">
                <a class="page-link" (click)="onPageChange(currentPage + 1)">Next</a>
              </li>
            </ul>
        </nav>
   
        </main>
        </div>
    </div>


  