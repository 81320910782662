<app-navbar ></app-navbar>

<div class="container-fluid">
  <div class="row">
    <!-- Sidebar -->
    <app-sidebar class="col-md-2 col-lg-2"></app-sidebar>

    
    <div class="content p-3">
        <router-outlet></router-outlet>
      </div>
  </div>
</div>