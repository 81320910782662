import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private url: string;
  private apiurl: string;
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.url = environment.BACKEND_BASE_URL_DEV; // Base URL from environment
    this.apiurl = 'api'; 
    this.apiUrl = 'analytics'; // Corrected typo
  }

  sendNotificationToAllUsers(notification: { title: string, message: string }): Observable<any> {
    return this.http.post(`${this.url}/${this.apiurl}/send_system_notification_to_all`, notification);
  }

  uploadFile(formData: FormData): Observable<any> { // Accept FormData for multiple files
    return this.http.post(`${this.url}/${this.apiUrl}/upload/`, formData); 
  }
}
