import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface Attendee {
  attendee: string;
  role: 'client' | 'therapist';
  confirmed: boolean;
  setter?: boolean;
}

interface Booking {
  id: number;
  title: string;
  date: Date;
  start_time: Date;
  end_time: Date;
  summary: string;
  attendees: Attendee[];
}

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css']
})
export class BookingDetailsComponent implements OnInit {

  @Input() booking!: Booking;

  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  ngOnInit(): void {
    // Initialization logic if needed
  }

  calculateDuration(): number {
    if (!this.booking) return 0;
    const start = new Date(this.booking.start_time).getTime();
    const end = new Date(this.booking.end_time).getTime();
    return Math.floor((end - start) / (1000 * 60)); // Duration in minutes
  }

  goBack(): void {
    this.router.navigate(['/bookings']); // Navigate to the bookings list or another route
  }
}
