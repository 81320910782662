import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent } from "./components/layout/navbar/navbar.component";
import { SidebarComponent } from "./components/layout/sidebar/sidebar.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";

declare var bootstrap: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'xaidi-dashboard';


  isSidebarClosed = true

  handleSidebarToggle(isClosed: boolean) {
    this.isSidebarClosed = isClosed;
  }


}
