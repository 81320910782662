<div *ngIf="booking" class="booking-details">
  <div class="details-header">
    <h6 class="booking-title"><strong>Meeting Title:</strong> {{ booking.title }}</h6>
    </div>

  <div class="details-body">
    <p><strong>Meeting ID:</strong> {{ booking.id }}</p>
    <p><strong>Created on:</strong> {{ booking.date | date: 'fullDate' }}</p>
    <p><strong>Start Time:</strong> {{ booking.start_time | date: 'shortTime' }}</p>
    <p><strong>End Time:</strong> {{ booking.end_time | date: 'shortTime' }}</p>
    <p><strong>Duration:</strong> {{ calculateDuration() }} minutes</p>
    <p><strong>Feedback:</strong> {{ booking.summary }}</p>
  </div>

  <button class="back-button" (click)="goBack()">Back</button>
</div>
