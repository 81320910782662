<!-- src/app/userdetails/userdetails.component.html -->
<div class="container-fluid" style="border-radius: 10px; background-color: white;border: 1px solid black ;">
  <div class="row">
    <app-sidebar></app-sidebar>
    <main class="col">
      <div class="container mt-4">
        <h2 class="my-4 text-center">Users</h2>

        <!-- Filter Section -->
        <div class="row mb-4">
          <div class="col-md-6">
            <label for="filter">Filter Users</label>
            <select id="filter" class="form-control" (change)="onFilterChange($event)">
              <option value="all">All Users</option>
              <option value="preview">Preview Users</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="search">Search</label>
            <input id="search" type="text" class="form-control" placeholder="Search by name" (input)="onSearchChange($event)">
          </div>
        </div>

        <!-- Loading Spinner -->
        <div *ngIf="loading" class="text-center">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>

        <!-- Users Table -->
        <div *ngIf="!loading && paginatedUsers.length > 0" class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead class="thead-dark">
              <tr>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of paginatedUsers">
                <td>{{ user.name || user.name || user.id }}</td>
                <td>
                  <button class="btn btn-info btn-sm me-2" (click)="viewUser(user.id)" title="View User">
                    <i class="bi bi-eye"></i> View
                  </button>
                  <button class="btn btn-warning btn-sm me-2" (click)="editUser(user.id)" title="Edit User">
                    <i class="bi bi-pencil"></i> Edit
                  </button>
                  <!-- <button class="btn btn-danger btn-sm" (click)="deleteUser(user.id)" title="Delete User">
                    <i class="bi bi-trash"></i> Delete
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- No Users Found Message -->
        <div *ngIf="!loading && paginatedUsers.length === 0" class="alert alert-warning text-center">
          No users found.
        </div>

        <!-- Pagination Section -->
        <nav *ngIf="totalPages > 1" aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item" [ngClass]="{'disabled': currentPage === 1}">
              <a class="page-link" href="#" (click)="prevPage(); $event.preventDefault()">Previous</a>
            </li>
            <li class="page-item" [ngClass]="{'disabled': currentPage === totalPages}">
              <a class="page-link" href="#" (click)="nextPage(); $event.preventDefault()">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </main>
  </div>
</div>
