<div class="modal-header">
    <h4 class="modal-title">Confirm Deletion</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete user with ID: {{ userId }}?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="confirmDelete()">Delete</button>
  </div>
  