import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export  interface Activity {
  id: string;
  action_type: string[];
  affected_users: string[];
  affected_items: string[];
  description: string;
  changes: any[];
  created_at: string;
  updated_at: string;
  user: string;
}
@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private url: string;
  private apiurl: string;

  constructor(private http: HttpClient) {
    this.url = environment.BACKEND_BASE_URL_DEV;
    this.apiurl = 'analytics';
    console.log('API URL:', this.url);

  }
  getActivities(): Observable<Activity[]> {
    return this.http.get<Activity[]>(`${this.url}/${this.apiurl}/user_activity`);
  }
}
