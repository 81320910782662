// src/app/services/user.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../models/user.model';

export interface PreviewUser{
  name: string;
  age: number;
  gender: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = `${environment.BACKEND_BASE_URL_DEV}/analytics`;

  constructor(private http: HttpClient) {}

  getUsers(page: number, itemsPerPage: number, filter: string = 'all', search: string = ''): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('itemsPerPage', itemsPerPage.toString())
      .set('filter', filter)
      .set('search', search);

    return this.http.get<any>(`${this.apiUrl}/fetch_users_data`, { params });
  }

  getGuestUsers(){
    return this.http.get<PreviewUser[]>(`${this.apiUrl}/guest_users`);
  }


  // New method to get user by ID
  getUserById(id: number): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/fetch_user/${id}`);
  }

  // New method to update user
  updateUser(id: number, user: User): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/update_user/${id}`, user);
  }
  
}
