import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NotificationService } from "../../services/notification.service";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent {
  notificationForm: FormGroup;
  fileUploadForm: FormGroup;
  isModalOpen = false;
  isFileUploadModalOpen = false;
  isMessageConfirmed = false;
  selectedFiles: File[] = [];
  uploadStatus: string = '';

  constructor(
    private fb: FormBuilder, 
    private notificationService: NotificationService,
    private toastr: ToastrService
  ) {
    this.notificationForm = this.fb.group({
      title: ['', Validators.required],
      message: ['', Validators.required]
    });

    this.fileUploadForm = this.fb.group({
      files: [null]
    });
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files.length) {
      this.selectedFiles = Array.from(event.target.files);
    }
  }

  uploadFiles() {
    if (this.selectedFiles.length > 0) {
      const formData = new FormData();
      this.selectedFiles.forEach(file => formData.append('files', file));

      this.notificationService.uploadFile(formData).subscribe(
        (response: any) => {
          this.uploadStatus = 'Files uploaded successfully!';
          this.toastr.success(this.uploadStatus);
          this.closeFileUploadModal();
        },
        (error: any) => {
          this.uploadStatus = 'Failed to upload files.';
          this.toastr.error(this.uploadStatus);
          console.error('Error details:', error);
        }
      );
    } else {
      this.uploadStatus = 'No files selected.';
      this.toastr.warning(this.uploadStatus);
    }
  }

  openNotificationModal() {
    this.isModalOpen = true;
  }

  closeNotificationModal() {
    this.isModalOpen = false;
    this.isMessageConfirmed = false;
    this.notificationForm.reset();
  }

  confirmMessage() {
    if (this.notificationForm.valid) {
      this.isMessageConfirmed = true;
      this.toastr.success('Message confirmed successfully!');
    }
  }

  sendNotification() {
    if (this.isMessageConfirmed) {
      const { title, message } = this.notificationForm.value;
      this.notificationService.sendNotificationToAllUsers({ title, message }).subscribe(
        (response) => {
          this.toastr.success('Notification sent successfully!');
          this.closeNotificationModal();
        },
        (error) => {
          this.toastr.error('Error sending notification. Please try again.');
        }
      );
    }
  }

  isFormInvalid(controlName: string): boolean {
    const control = this.notificationForm.get(controlName);
    return !!(control && control.invalid && control.touched);
  }

  openFileUploadModal() {
    this.isFileUploadModalOpen = true;
  }

  closeFileUploadModal() {
    this.isFileUploadModalOpen = false;
    this.fileUploadForm.reset();
    this.selectedFiles = [];
  }
}
