import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

export interface User {
  id: string;
  cognito_sub: string;
  tokens: number;
  bonus_tokens: number;
  wallet: any; // Adjust type if needed
  izola_id: string;
  next_token_update: string;
  created_at: string;
  date_modified: string;
  persona_style: string;
  referral_code: string;
  be_brief: boolean;
  last_login: string;
  last_login_notif: any; // Adjust type if needed
  push_tokens: string[];
  time_zone: string;
  bot_settings: {
    persona: string;
    creativity: number;
    language: string;
    be_brief: boolean;
  };
  community_admin: boolean;
  therapy_sessions: number;
  subscription: string;
  identity: string;
  doctor: boolean;
  name: string;
}

export interface Receipt {
  id: string;
  user: User;
  transaction_identifier: string;
  purchase_date: string;
  product_identifier: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReceiptsService {
  private url: string;
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.url = environment.BACKEND_BASE_URL_DEV; // Base URL from environment
    this.apiUrl = 'analytics'; // API endpoint for analytics
  }

  getReceipts(): Observable<Receipt[]> {
    return this.http.get<Receipt[]>(`${this.url}/${this.apiUrl}/revenue-cat-receipts`);
  }

  // Get number of receipts between two dates
  getReceiptsCountByDateRange(startDate: string, endDate: string): Observable<any[]> {
    const params = new HttpParams()
      .set('start_date', startDate)
      .set('end_date', endDate);
  
    return this.http.get<any[]>(`${this.url}/${this.apiUrl}/receipts-count`, { params })
      .pipe(
        catchError(error => {
          console.error('Error fetching receipts count data', error);
          return throwError(() => new Error('Error fetching receipts count data'));
        })
      );
}
}