<div class="container">
  <div class="card">
    <div class="card-body">
      <!-- Logo Image -->
      <div class="text-center mb-4">
        <img src="https://storage.googleapis.com/saidi-dashboard-assets/xaidi_logo.svg" alt="XAIDI" style="width:100px;">
      </div>
      <form (ngSubmit)="login()">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input type="email" class="form-control" id="email" name="email" [(ngModel)]="email" required>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input type="password" class="form-control" id="password" name="password" [(ngModel)]="password" required>
        </div>
        <button type="submit" class="btn btn-primary">Login</button>
        <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
      </form>
    </div>
  </div>
</div>
