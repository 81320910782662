import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js/auto';
import moment from 'moment';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  startDateActive: string = moment().startOf('month').format('YYYY-MM-DD');
  endDateActive: string = moment().endOf('month').format('YYYY-MM-DD');
  startDateBookings: string = moment().startOf('month').format('YYYY-MM-DD');
  endDateBookings: string = moment().endOf('month').format('YYYY-MM-DD');
  activeUsersChart: any;
  bookingsChart: any;

  constructor(private apiService: DashboardService) { }

  ngOnInit(): void {
    this.createActiveUsersChart();
    this.createBookingsChart();
  }

  getDaysArray(start: string, end: string): string[] {
    const startDate = moment(start);
    const endDate = moment(end);
    const daysArray = [];

    while (startDate <= endDate) {
      daysArray.push(startDate.format('YYYY-MM-DD'));
      startDate.add(1, 'day');
    }

    return daysArray;
  }

  fetchActiveUsersData(start: string, end: string): void {
    this.apiService.getActiveUsersBetweenDates(start, end).subscribe((response: any[]) => {
      const labels = this.getDaysArray(start, end);
      const data = new Array(labels.length).fill(0);

      response.forEach((entry: { date: string; count: number }) => {
        const loginDate = moment(entry.date).format('YYYY-MM-DD');
        const index = labels.indexOf(loginDate);
        if (index > -1) {
          data[index] = entry.count;
        }
      });

      this.updateActiveUsersChart(labels, data);
    }, (error: any) => {
      console.error('Error fetching active users data:', error);
    });
  }

  createActiveUsersChart(): void {
    const ctx = (document.getElementById('activeUsersChart') as HTMLCanvasElement).getContext('2d');
    if (ctx) {
      this.activeUsersChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.getDaysArray(this.startDateActive, this.endDateActive),
          datasets: [{
            label: 'Active Users',
            data: new Array(this.getDaysArray(this.startDateActive, this.endDateActive).length).fill(0),
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
            fill: false
          }]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              min: 0,
              title: {
                display: true,
                text: 'No of Active Users'
              }
            }
          }
        }
      });

      this.fetchActiveUsersData(this.startDateActive, this.endDateActive);
    }
  }

  updateActiveUsersChart(labels: string[], data: number[]): void {
    if (this.activeUsersChart) {
      this.activeUsersChart.data.labels = labels;
      this.activeUsersChart.data.datasets[0].data = data;
      this.activeUsersChart.update();
    }
  }

  fetchBookingsData(start: string, end: string): void {
    this.apiService.getDisputedMeetingsBetweenDates(start, end).subscribe((response: { disputed_meetings_per_day: { date: string; count: number }[] }) => {
      // Log the full response to check its structure
      console.log('Bookings Data:', response);
  
      // Extract the array from the response
      const bookingsData = response.disputed_meetings_per_day || [];
  
      // Check if bookingsData is an array
      if (Array.isArray(bookingsData)) {
        const labels = this.getDaysArray(start, end);
        const data = new Array(labels.length).fill(0);
  
        bookingsData.forEach((entry: { date: string; count: number }) => {
          const bookingDate = moment(entry.date).format('YYYY-MM-DD');
          const index = labels.indexOf(bookingDate);
          if (index > -1) {
            data[index] = entry.count;
          }
        });
  
        this.updateBookingsChart(labels, data);
      } else {
        console.error('Disputed meetings data is not an array:', bookingsData);
      }
    }, (error: any) => {
      console.error('Error fetching bookings data:', error);
    });
  }
  
  

  createBookingsChart(): void {
    const ctx = (document.getElementById('bookingsChart') as HTMLCanvasElement).getContext('2d');
    if (ctx) {
      this.bookingsChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.getDaysArray(this.startDateBookings, this.endDateBookings),
          datasets: [{
            label: 'Bookings',
            data: new Array(this.getDaysArray(this.startDateBookings, this.endDateBookings).length).fill(0),
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
            fill: false
          }]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              min: 0,
              title: {
                display: true,
                text: 'No of Bookings'
              }
            }
          }
        }
      });

      this.fetchBookingsData(this.startDateBookings, this.endDateBookings);
    }
  }

  updateBookingsChart(labels: string[], data: number[]): void {
    if (this.bookingsChart) {
      this.bookingsChart.data.labels = labels;
      this.bookingsChart.data.datasets[0].data = data;
      this.bookingsChart.update();
    }
  }

  onDateChangeActive(start: string, end: string): void {
    this.fetchActiveUsersData(start, end);
  }

  onDateChangeBookings(start: string, end: string): void {
    this.fetchBookingsData(start, end);
  }
}
