
<div class="container-fluid">
  <div class="row">
    <app-sidebar></app-sidebar>

   <!-- Main Content -->
<main class="col-md-9">
  <div class="outer-container mt-4">
    <div class="inner-container">
      <h6 class="activity-header my-4">
        <span>Activities</span>
      </h6>

      <!-- Filter Section -->
      <div class="row mb-4">
        <div class="col-md-6">
          <label for="activityFilter">Filter Activities</label>
          <select id="activityFilter" class="form-control" (change)="onFilterChange($event)">
            <option value="all">All Activities</option>
            <option value="create-meeting">Created Meetings</option>
            <option value="update-meeting">Updated Meetings</option>
          </select>
        </div>
      </div>

      <!-- Loading Spinner -->
      <div *ngIf="loading" class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>

      <!-- Display No Activities Message if List is Empty -->
      <div *ngIf="!loading && filteredActivities.length === 0" class="text-center text-muted">
        No activities found.
      </div>

      <!-- Activity List and Details -->
      <div *ngIf="!loading && filteredActivities.length > 0" class="row">
        <!-- Activity List Column -->
        <div class="col-md-4">
          <div class="list-container">
            <div class="list-group">
              <a *ngFor="let activity of paginatedActivities" 
                 class="list-group-item list-group-item-action d-flex align-items-start" 
                 (click)="selectActivity(activity)">
                
                <!-- User Profile Icon -->
                <i [class]="getIconClass(activity) + ' profile-icon me-2'"></i>
                
                <div>
                  <h6 class="mb-1">{{ getActivityTitle(activity) }}</h6>
                  <p class="mb-1">{{ activity.description || 'No description available' }}</p>
                  <small>{{ activity.created_at | date:'short' }}</small>
                  <br>
                  <!-- Display the user name here -->
                  <p>By: <small>{{ activity.user.name || 'Unknown User' }}</small></p>
                </div>
              </a>
            </div>

          <!-- Pagination Controls -->
          <nav *ngIf="totalPages > 1" aria-label="Page navigation">
            <ul class="pagination justify-content-center mt-3">
              <li class="page-item" [ngClass]="{'disabled': currentPage === 1}">
                <a class="page-link" href="#" (click)="previousPage()">Previous</a>
              </li>
              <li class="page-item" [ngClass]="{'disabled': currentPage === totalPages}">
                <a class="page-link" href="#" (click)="nextPage()">Next</a>
              </li>
            </ul>
          </nav>
          </div>
        </div>

        <!-- Activity Details Column -->
        <div class="col-md-8">
          <div *ngIf="selectedActivity" class="activity-details">
            <h4>{{ getActivityTitle(selectedActivity) }}</h4>
            <p><strong>Date:</strong> {{ selectedActivity.created_at | date:'fullDate' }}</p>
            <p><strong>Type:</strong> {{ selectedActivity.action_type.join(', ') }}</p>
            <p><strong>Description:</strong> {{ selectedActivity.description || 'No description available' }}</p>
            <!-- Display the user name here -->
            <p><strong>User:</strong> {{ selectedActivity.user.name || 'Unknown User' }}</p>
          </div>
          <div *ngIf="!selectedActivity" class="text-center">
            <p>Select an activity to see the details</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

  </div>
</div>
