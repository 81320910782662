import { Component, OnInit } from '@angular/core';
import { ActivityService } from '../../services/activity.service';

interface User {
  id: string;
  name: string;
}
interface Activity {
  id: string;
  action_type: string[];
  affected_users: string[];
  affected_items: string[];
  description: string;
  changes: any[];
  created_at: string;
  updated_at: string;
  user: User;
}

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  activities: Activity[] = [];
  filteredActivities: Activity[] = [];
  paginatedActivities: Activity[] = [];
  selectedFilter: string = 'all'; // Default filter
  selectedActivity: Activity | null = null;
  currentPage: number = 1;
  itemsPerPage: number = 4;
  totalPages: number = 1;
  pageNumbers: number[] = [];
  loading: boolean = true; // Loading state

  constructor(private activityService: ActivityService) {}

  ngOnInit(): void {
    this.fetchActivities();
  }

  fetchActivities(): void {
    this.loading = true; // Start loading
    this.activityService.getActivities().subscribe(
      (data: any) => {
        this.loading = false; // Stop loading
        if (data && Array.isArray(data.results)) {
          this.activities = data.results;
        } else {
          console.error('Unexpected API response: expected an array');
          this.activities = [];
        }
        this.filteredActivities = [...this.activities];
        this.totalPages = Math.ceil(this.filteredActivities.length / this.itemsPerPage);
        this.paginate();
      },
      (error) => {
        this.loading = false; // Stop loading on error
        console.error('Error fetching activities', error);
        this.activities = [];
        this.applyFilterAndPagination();
      }
    );
  }

  filterActivities(filter: string): void {
    this.selectedFilter = filter;
    this.applyFilterAndPagination();
  }

  applyFilterAndPagination(): void {
    if (this.selectedFilter === 'all') {
      this.filteredActivities = [...this.activities];
    } else if (this.selectedFilter === 'create-meeting') {
      this.filteredActivities = this.activities.filter(activity => activity.action_type.includes('create-meeting'));
    } else if (this.selectedFilter === 'update-meeting') {
      this.filteredActivities = this.activities.filter(activity => activity.action_type.includes('update-meeting'));
    }

    this.totalPages = Math.ceil(this.filteredActivities.length / this.itemsPerPage);
    this.currentPage = 1; // Reset to first page
    this.paginate();
  }

  paginate(): void {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;

    this.paginatedActivities = this.filteredActivities.slice(start, end);
    this.pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  selectActivity(activity: Activity): void {
    this.selectedActivity = activity;
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginate();
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginate();
    }
  }

  onFilterChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.filterActivities(target.value);
  }

  getIconClass(activity: Activity): string {
    switch (activity.action_type[0]) {
      case 'create-meeting': return 'bi bi-person';
      case 'update-meeting': return 'bi bi-person-check';
      case 'delete-meeting': return 'bi bi-person-x';
      default: return 'bi bi-question-circle';
    }
  }

  getActivityTitle(activity: Activity): string {
    return activity.action_type.join(', ');
  }
}
