import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<any>;

  constructor(private afAuth: AngularFireAuth, private router: Router) {
    this.user$ = this.afAuth.authState;

    // Set persistence
    this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        console.log('Session persistence set');
      })
      .catch(error => {
        console.error('Failed to set session persistence:', error);
      });
  }

  login(email: string, password: string): Observable<boolean> {
    return from(this.afAuth.signInWithEmailAndPassword(email, password)).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  logout(): Promise<void> {
    return this.afAuth.signOut();
  }

  // New method to get the logged-in user's email
  getUserEmail(): Observable<string | null> {
    return this.user$.pipe(
      map(user => user ? user.email : null)
    );
  }
}
