export const environment = {
    production: false,
    BACKEND_BASE_URL_DEV: 'https://gpt.izola.tech/server',
    // BACKEND_BASE_URL_DEV: 'http://127.0.0.1:8000',

    firebaseConfig: {
        apiKey: "AIzaSyBNn17m4QEUbAovzPeYUOjfMOPcISH9V7E",
        authDomain: "xaidi-dashboard-dev.firebaseapp.com",
        projectId: "xaidi-dashboard-dev",
        storageBucket: "xaidi-dashboard-dev.appspot.com",
        messagingSenderId: "1096108479131",
        appId: "1:1096108479131:web:c055e9b3c0dcc1bdb46c3e"
        
    }
  };