import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user.model';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {
  userId: number | null = null;
  userForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService // Inject UserService
  ) {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      identity: [{ value: '', disabled: true }], // Disabled field
      therapistName: [''],
      sessions: [{ value: '', disabled: true }] // Disabled field
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.userId = +params.get('id')!;
      if (this.userId) {
        this.userService.getUserById(this.userId).subscribe(
          (user: User) => {
            this.userForm.patchValue(user);
          },
          error => {
            console.error('Error fetching user data', error);
            // Handle error (e.g., redirect to an error page or show an error message)
          }
        );
      }
    });
  }

  onSubmit(): void {
    if (this.userForm.valid) {
      this.userService.updateUser(this.userId!, this.userForm.value).subscribe(
        () => {
          alert('User details updated!');
          this.router.navigate(['/user-details']);
        },
        error => {
          console.error('Error updating user details', error);
          // Handle error (e.g., show an error message)
        }
      );
    }
  }
}
