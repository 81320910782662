import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


interface Duration {
  joined: string;
  left: string;
  time_elapsed_min: number;
}

interface AttendeeDuration {
  attendee: string;
  deductedTokens: boolean;
  durations: Duration[];
}

interface Attendee {
  attendee: string;
  confirmed: boolean;
  setter: boolean;
}

interface Booking {
  id: string;
  title: string;
  date: string;
  duration: number | null;
  start_time: string;
  end_time: string;
  attendees: Attendee[];
  created_at: string;
  summary: string;
  date_modified: string;
  attendee_durations: AttendeeDuration[];
}


@Injectable({
  providedIn: 'root'
})
export class BookingsService {
  private url: string;
  private apiurl: string;

  constructor(private http: HttpClient) {
    this.url = environment.BACKEND_BASE_URL_DEV;
    this.apiurl = 'analytics';
  }

  getBookings(): Observable<string> {
    return this.http.get<string>(`${this.url}/${this.apiurl}/fetch_appointments`, { responseType: 'text' as 'json' });
  }
}
