import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { UserdetailsComponent } from './components/users/userdetails/userdetails.component';
import { ViewusersComponent } from './components/users/viewusers/viewusers.component';
import { EdituserComponent } from './components/users/edituser/edituser.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { ToolsComponent } from './components/tools/tools.component';
import { ActivityComponent } from './components/activity/activity.component';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { ReceiptsComponent } from './components/receipts/receipts.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent  },
  { path: 'nav', component: NavbarComponent},
  { path: 'sidebar', component: SidebarComponent},
  { path: 'user-details', component: UserdetailsComponent },
  { path: 'view-user/:id', component: ViewusersComponent },
  { path: 'edit-user/:id', component: EdituserComponent },
  { path: 'bookings', component: BookingsComponent },
  { path: 'bookings-details', component: BookingDetailsComponent },
  { path: 'receipts', component: ReceiptsComponent },
  { path: 'activity', component: ActivityComponent },
  { path: 'tools', component: ToolsComponent },
  { path: '**', redirectTo: '/login' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
