<!-- src/app/view-user/view-user.component.html -->
<div class="modal-header">
    <h4 class="modal-title">User Details</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="user">
      <p><strong>Username:</strong> {{ user.name }}</p>
      <p><strong>Identity:</strong> {{ user.identity }}</p>
      <div *ngIf="user.identity === 'preview'">
        <p><strong>Age:</strong> {{ user.age }}</p>
        <p><strong>Gender:</strong> {{ user.gender }}</p>
      </div>
      <div *ngIf="user.identity === 'client'">
        <p><strong>Therapist:</strong> {{ user.therapistName || 'N/A' }}</p>
        <p><strong>Sessions:</strong> {{ user.sessions || 0 }}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Close</button>
  </div>
  