<div style="width: fit-content; height: 100vh;" class="d-flex flex-column flex-shrink-0 p-3 sidebar" [class.closed]="isSidebarClosed">
  
    <div>
      <img src="https://storage.googleapis.com/saidi-dashboard-assets/xaidi_logo.svg" alt="XAIDI" style="width:100px;">
    </div>
    
    <hr>
    
    <button class="toggle-btn" (click)="toggleSidebar()" style="background: none; border: none; color: rgb(12, 12, 12); cursor: pointer;">
      <i class="bi bi-list icon"></i>
      <span *ngIf="!isSidebarClosed" style="margin-left: 4px; color: black;">MENU</span>
    </button>
    
    <ul class="nav nav-pills flex-column mb-auto mt-3">
      <li class="nav-item">
        <a routerLink="/dashboard" routerLinkActive="active" href="dashboard" class="nav-link" aria-current="page">
          <i class="bi bi-house-door icon"></i>
          <span *ngIf="!isSidebarClosed">Dashboard</span>
        </a>
      </li>
      <li>
        <a routerLink="/user-details" routerLinkActive="active" href="user-details" class="nav-link link-dark mt-4">
          <i class="bi bi-people icon"></i>
          <span *ngIf="!isSidebarClosed">Users</span>
        </a>
      </li>
      <li>
        <a routerLink="/bookings" routerLinkActive="active" href="bookings" class="nav-link link-dark">
          <i class="bi bi-bookmark icon"></i>
          <span *ngIf="!isSidebarClosed">Bookings</span>
        </a>
      </li>
      <li>
        <a routerLink="/receipts" routerLinkActive="active" href="receipts" class="nav-link link-dark">
          <i class="bi bi-file-earmark-text icon"></i>
          <span *ngIf="!isSidebarClosed">Receipts</span>
        </a>
      </li>
      <li>
        <a routerLink="/activity" routerLinkActive="active" href="activity" class="nav-link link-dark">
          <i class="bi bi-activity icon"></i>
          <span *ngIf="!isSidebarClosed">Activity</span>
        </a>
      </li>
      <li>
        <a href="/tools" routerLinkActive="active" href="tools" class="nav-link link-dark">
          <i class="bi bi-tools icon"></i>
          <span *ngIf="!isSidebarClosed">Tools</span>
        </a>
      </li>
    </ul>
    
    <div class="user-info">
      <ul class="navbar-nav ms-auto" style="display: flex; align-items: center;">
        <li class="nav-item dropdown" style="position: relative;">
          <div class="nav-link" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="display: flex; align-items: center; cursor: pointer;">
            <i class="bi bi-person-circle icon" style="margin-right: 5px;">Profile</i>
            <!-- Commented out the userEmail part -->
            <!-- <span *ngIf="!isSidebarClosed" style="font-size: 0.875rem;">{{ userEmail }}</span> -->
            <i class="bi bi-three-dots-vertical icon" style="margin-left: 10px;"></i>
          </div>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
            <li>
              <a class="dropdown-item" (click)="logout()" href="#"><i class="bi bi-box-arrow-right"></i> Logout</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  