import { Component, OnInit } from '@angular/core';
import { ReceiptsService } from '../../services/receipts.service';
import { DatePipe } from '@angular/common';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';

interface User {
  id: string;
  cognito_sub: string;
  tokens: number;
  bonus_tokens: number;
  wallet: any; // Adjust type if needed
  izola_id: string;
  next_token_update: string;
  created_at: string;
  date_modified: string;
  persona_style: string;
  referral_code: string;
  be_brief: boolean;
  last_login: string;
  last_login_notif: any; // Adjust type if needed
  push_tokens: string[];
  time_zone: string;
  bot_settings: {
    persona: string;
    creativity: number;
    language: string;
    be_brief: boolean;
  };
  community_admin: boolean;
  therapy_sessions: number;
  subscription: string;
  identity: string;
  doctor: boolean;
  name: string;
}

interface Receipt {
  id: string;
  user: User;
  transaction_identifier: string;
  purchase_date: string;
  product_identifier: string;
}

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.css'],
  providers: [DatePipe]
})
export class ReceiptsComponent implements OnInit {
  receipts: Receipt[] = [];
  sortedReceipts: Receipt[] = [];
  sortColumn: 'userName' | 'transaction_identifier' | 'purchase_date' | 'product_identifier' = 'userName';
  sortOrder: 'asc' | 'desc' = 'asc';

  // Pagination variables
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  searchTerm: string = '';
  startDate: string = moment().startOf('month').format('YYYY-MM-DD');
  endDate: string = moment().endOf('month').format('YYYY-MM-DD');
  chart: any;

  // Chart Data and Options
  public chartData: ChartConfiguration['data'] = {
    labels: [],
    datasets: [
      {
        label: 'Number of Receipts',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }
    ]
  };

  constructor(private receiptsService: ReceiptsService, private datePipe: DatePipe, private http: HttpClient) {}

  ngOnInit(): void {
    this.getReceipts();
    this.createActiveUsersChart();
  }
  getDaysArray(start: string, end: string): string[] {
    const startDate = moment(start);
    const endDate = moment(end);
    const daysArray = [];

    while (startDate <= endDate) {
      daysArray.push(startDate.format('YYYY-MM-DD'));
      startDate.add(1, 'day');
    }

    return daysArray;
  }

 

  getTotalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  getReceipts(): void {
    this.receiptsService.getReceipts().subscribe((data: Receipt[]) => {
      this.receipts = data;
      this.totalItems = this.receipts.length;
      this.applyFilterAndSort();
    });
  }

  sortData(column: 'userName' | 'transaction_identifier' | 'purchase_date' | 'product_identifier'): void {
    if (this.sortColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortOrder = 'asc';
    }
    this.applyFilterAndSort();
  }

  applyFilterAndSort(): void {
    let filteredReceipts = this.receipts.filter(receipt =>
      receipt.user.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      receipt.transaction_identifier.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      receipt.product_identifier.toLowerCase().includes(this.searchTerm.toLowerCase())
    );

    filteredReceipts = filteredReceipts.sort((a, b) => {
      let valueA = (a as any)[this.sortColumn];
      let valueB = (b as any)[this.sortColumn];

      if (typeof valueA === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA < valueB) return this.sortOrder === 'asc' ? -1 : 1;
      if (valueA > valueB) return this.sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    this.totalItems = filteredReceipts.length;
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    this.sortedReceipts = filteredReceipts.slice(startIndex, startIndex + this.itemsPerPage);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.applyFilterAndSort();
  }

  onSearchChange(): void {
    this.applyFilterAndSort();
  }
  fetchReceipts(start: string, end: string): void {
    this.receiptsService.getReceiptsCountByDateRange(start, end).subscribe((response: any[]) => {
      const labels = this.getDaysArray(start, end);
      const data = new Array(labels.length).fill(0);

      response.forEach((entry: { date: string; count: number }) => {
        const loginDate = moment(entry.date).format('YYYY-MM-DD');
        const index = labels.indexOf(loginDate);
        if (index > -1) {
          data[index] = entry.count;
        }
      });

      this.updateChart(labels, data);
    }, (error: any) => {
      console.error('Error fetching active users data:', error);
    });
  }
  createActiveUsersChart(): void {
    const ctx = (document.getElementById('receiptschart') as HTMLCanvasElement).getContext('2d');
    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.getDaysArray(this.startDate, this.endDate),
          datasets: [{
            label: 'Receipts',
            data: new Array(this.getDaysArray(this.startDate, this.endDate).length).fill(0),
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            fill: false
          }]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              min: 0,
              max: 10,
              title: {
                display: true,
                text: 'No of Payments'
              }
            }
          }
        }
      });

      // Fetch and update chart data
      this.fetchReceipts(this.startDate, this.endDate);
    }
  }
  // Fetch and display chart data
  updateChart(labels: string[], data: number[]): void {
    if (this.chart) {
      this.chart.data.labels = labels;
      this.chart.data.datasets[0].data = data;
      this.chart.update();
    }
  }
   

  onDateChange(): void {
    this.fetchReceipts(this.startDate, this.endDate);
    
  }
  
}
