<!-- Main Container -->
<div class="container-fluid">
  <!-- Sidebar Component -->
  <app-sidebar></app-sidebar>

  <!-- Main Content Area -->
  <div class="main-content">
    <h5>Tools</h5>
    <!-- First Row of Cards -->
    <div class="row">
      <div class="col-md-4">
        <!-- Notification Card -->
        <div class="card" (click)="openNotificationModal()">
          <div class="card-body">
            <h5 class="card-title">Notifications</h5>
            <p class="card-text">Click to open modal</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <!-- Card 2 with File Upload -->
        <div class="card" (click)="openFileUploadModal()">
          <div class="card-body">
            <h5 class="card-title">Upload Files</h5>
            <p class="card-text">Click to upload files</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- File Upload Modal -->
<div class="file-upload-modal" [ngClass]="{'show': isFileUploadModalOpen}">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title">Upload Files</h6>
      <button type="button" class="btn-close" (click)="closeFileUploadModal()"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="fileUploadForm" (ngSubmit)="uploadFiles()">
        <div class="mb-3">
          <label for="fileUpload" class="form-label">Choose Files</label>
          <input
            type="file"
            class="form-control"
            id="fileUpload"
            multiple
            (change)="onFileChange($event)"
          />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeFileUploadModal()">Cancel</button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="!selectedFiles.length"
          >
            Upload
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Notification Modal -->
<div class="notification-modal" [ngClass]="{'show': isModalOpen}">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title">Send Notification</h6>
      <button type="button" class="btn-close" (click)="closeNotificationModal()"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="notificationForm">
        <!-- Title Input Field -->
        <div class="mb-3">
          <label for="title" class="form-label">Title</label>
          <input
            formControlName="title"
            type="text"
            class="form-control"
            placeholder="Enter the title (e.g., IMPORTANT NOTICE)"
            [attr.disabled]="isMessageConfirmed ? true : null"
          />
        </div>
        <!-- Message Textarea -->
        <div class="mb-3">
          <label for="message" class="form-label">Message</label>
          <textarea
            formControlName="message"
            placeholder="Enter your message"
            class="form-control"
            [attr.disabled]="isMessageConfirmed ? true : null"
          ></textarea>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeNotificationModal()">Cancel</button>
      <!-- Confirm button -->
      <button
        *ngIf="!isMessageConfirmed"
        class="btn btn-primary mt-3"
        (click)="confirmMessage()"
        [disabled]="notificationForm.get('message')?.value?.trim() === '' || notificationForm.get('title')?.value?.trim() === ''"
      >
        Confirm
      </button>
      <!-- Send button -->
      <button
        *ngIf="isMessageConfirmed"
        class="btn btn-success mt-3"
        (click)="sendNotification()"
      >
        Send
      </button>
    </div>
  </div>
</div>
