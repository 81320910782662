// dashboard.service.ts
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export interface TotalUsersResponse {
  user_count: number; 
}
export interface TotalActiveUsersResponse {
  active_user_count: number; 
}
export interface TotalAppointmentsResponse {
  appointments_count:number;
}
export interface ReceiptsResponse {
  count:number;
}
export interface TotalCompletedSessionsResponse {
  sessions_count:number;
}
export interface Feedback {
  id: string;
  user_id?: string;
  user_name?: string;
  rating: number;
  focus_areas: string[];
  message: string;
  created_at: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  
  private url: string;
  private apiurl: string;

  constructor(private http: HttpClient) {
    this.url = environment.BACKEND_BASE_URL_DEV;
    this.apiurl = 'analytics';
  }

  getTotalUsers(): Observable<TotalUsersResponse> {
    return this.http.get<TotalUsersResponse>(`${this.url}/${this.apiurl}/total_users`)
      .pipe(
        catchError(error => {
          console.error('Error fetching total users', error);
          return throwError(() => new Error('Error fetching total users'));
        })
      );
  }
  getTotalActiveUsers(): Observable<TotalActiveUsersResponse> {
    return this.http.get<TotalActiveUsersResponse>(`${this.url}/${this.apiurl}/total_active_users`)
      .pipe(
        catchError(error => {
          console.error('Error fetching total users', error);
          return throwError(() => new Error('Error fetching total users'));
        })
      );
  }  
  

  getTotalAppointments(): Observable<TotalAppointmentsResponse> {
    return this.http.get<TotalAppointmentsResponse>(`${this.url}/${this.apiurl}/total_appointments`)
      .pipe(
        catchError(error => {
          console.error('Error fetching total appoitments', error);
          return throwError(() => new Error('Error fetching total appointments'));
        })
      );
  }
  getTotalReceipts(): Observable<ReceiptsResponse> {
    return this.http.get<ReceiptsResponse>(`${this.url}/${this.apiurl}/count_receipts`)
      .pipe(
        catchError(error => {
          console.error('Error fetching total receipts', error);
          return throwError(() => new Error('Error fetching total receipts'));
        })
      );
  }

  getTotalCompletedSessions(): Observable<number> {
    return this.http.get<number>(`${this.url}/${this.apiurl}/attended_meetings`)
      .pipe(
        catchError(error => {
          console.error('Error fetching total completed sessions', error);
          return throwError(() => new Error('Error fetching total completed sessions'));
        })
      );
  }
  
  getActiveUsersBetweenDates(startDate: string, endDate: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/${this.apiurl}/active_users_overtime/`, {
      params: {
        start_date: startDate,
        end_date: endDate
      }
    }).pipe(
      catchError(error => {
        console.error('Error fetching active users data', error);
        return throwError(() => new Error('Error fetching active users data'));
      })
    );
  }
  getFeedbackList(): Observable<Feedback[]> {
    return this.http.get<Feedback[]>(`${this.url}/${this.apiurl}/feedback`)
      .pipe(
        catchError(error => {
          console.error('Error fetching feedback list', error);
          return throwError(() => new Error('Error fetching feedback list'));
        })
      );
  }

  getAttendedAppointmentsBetweenDates(startDate: string, endDate: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/${this.apiurl}/attended_appointments_overtime/`, {
      params: {
        start_date: startDate,
        end_date: endDate
      }
    }).pipe(
      catchError(error => {
        console.error('Error fetching attended appointments data', error);
        return throwError(() => new Error('Error fetching attended appointments data'));
      })
    );
  }
  getDisputedMeetingsBetweenDates(startDate: string, endDate: string): Observable<{ disputed_meetings_per_day: { date: string; count: number }[] }> {
    return this.http.get<{ disputed_meetings_per_day: { date: string; count: number }[] }>(`${this.url}/${this.apiurl}/meetings-disputed`, {
      params: {
        start_date: startDate,
        end_date: endDate
      }
    }).pipe(
      catchError(error => {
        console.error('Error fetching disputed meetings data', error);
        return throwError(() => new Error('Error fetching disputed meetings data'));
      })
    );
  }
  
}