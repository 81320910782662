import { Component, Input } from '@angular/core';
//import { User } from '@angular/fire/auth';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../models/user.model';
@Component({
  selector: 'app-preview-users',
  templateUrl: './preview-users.component.html',
  styleUrl: './preview-users.component.css'
})
export class PreviewUsersComponent {

  @Input() user!: User;

  constructor(public activeModal: NgbActiveModal) {}

}
