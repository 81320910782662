<div class="container-fluid">
  <div class="row">
    <!-- Sidebar -->
    <app-sidebar class="col-md-4 col-lg-2"></app-sidebar>

    <!-- Main Content -->
    <main class="col-md-8 col-lg-10" style="border-radius: 10px; margin-left: 200px; margin-right: auto;">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h5>Dashboard Overview</h5>
      </div>
      <app-cards></app-cards>

      <!-- Row for both charts -->
      <div class="row mb-3">
        <!-- Active Users Chart -->
        <div class="col-md-6">
          <div class="card shadow-sm">
            <div class="card-body">
              <h5 class="card-title">Active Users Chart</h5>
              <div class="row">
                <div class="col-md-6">
                  <label for="startDateActive">Start Date:</label>
                  <input  type="date" id="startDateActive" [(ngModel)]="startDateActive" #startDateActiveInput (change)="onDateChangeActive(startDateActiveInput.value, endDateActive)">
                </div>
                <div class="col-md-6">
                  <label for="endDateActive">End Date:</label>
                  <input type="date" id="endDateActive" [(ngModel)]="endDateActive" #endDateActiveInput (change)="onDateChangeActive(startDateActive, endDateActiveInput.value)">
                </div>
              </div>
              <canvas id="activeUsersChart"></canvas>
            </div>
          </div>
        </div>

        <!-- Bookings Chart -->
        <div class="col-md-6">
          <div class="card shadow-sm">
            <div class="card-body">
              <h5 class="card-title">Bookings Chart</h5>
              <div class="row">
                <div class="col-md-6">
                  <label for="startDateBookings">Start Date:</label>
                  <input type="date" id="startDateBookings" [(ngModel)]="startDateBookings" #startDateBookingsInput (change)="onDateChangeBookings(startDateBookingsInput.value, endDateBookings)">
                </div>
                <div class="col-md-6">
                  <label for="endDateBookings">End Date:</label>
                  <input type="date" id="endDateBookings" [(ngModel)]="endDateBookings" #endDateBookingsInput (change)="onDateChangeBookings(startDateBookings, endDateBookingsInput.value)">
                </div>
              </div>
              <canvas id="bookingsChart"></canvas>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
