import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user.model';
import { DeleteuserComponent } from '../deleteuser/deleteuser.component';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewusersComponent } from '../viewusers/viewusers.component';

interface PreviewUser{
  name: string;
  age: number;
  gender: string;
}
@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.css']
})
export class UserdetailsComponent implements OnInit {
  users: User[] = [];
  guestUsers: User[] = []; // New array to store guest users
  filteredUsers: User[] = [];
  paginatedUsers: User[] = [];
  selectedFilter: string = 'all'; // Default filter
  searchTerm: string = '';
  currentPage: number = 1;
  itemsPerPage: number = 8;
  totalPages: number = 1;
  loading: boolean = true;

  constructor(
    private userService: UserService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.fetchUsers(this.currentPage);
    this.fetchGuestUsers(); // Fetch guest users
  }

  fetchUsers(page: number): void {
    this.loading = true;
    this.userService.getUsers(page, this.itemsPerPage).subscribe(
      (data: any) => {
        if (!data.results || !Array.isArray(data.results)) {
          console.error('Invalid data structure:', data);
          this.users = [];
          this.totalPages = 0;
         
          return;
        }
  
        this.users = data.results.sort((a: User, b: User) => {
          if (a.name && !b.name) {
            return -1;
          } else if (!a.name && b.name) {
            return 1;
          } else {
            return 0;
          }
        });
        this.paginate();
        this.totalPages = Math.ceil(data.count / this.itemsPerPage);
        this.loading = false;
        this.filterUsers();
      },
      (error) => {
        console.error('Error fetching users:', error);
        this.users = [];
        this.totalPages = 0;
        this.loading = false;
      }
    );
  }

  fetchGuestUsers(): void {
    this.loading = true;
    this.userService.getGuestUsers().subscribe(
      (data: any) => {
        this.guestUsers = data.results || [];
        console.log('Guest users:', this.guestUsers);
        this.loading = false;
        this.filterUsers();
      },
      (error) => {
        console.error('Error fetching guest users:', error);
        this.guestUsers = [];
        this.loading = false;
      }
    );
  }

  filterUsers(): void {
    let filtered = this.users;

    if (this.selectedFilter === 'preview') {
      filtered = this.guestUsers; // Use guest users for preview filter
    } else {
      // Filter by search term
      if (this.searchTerm) {
        filtered = filtered.filter(user => user.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
      }
    }

    this.filteredUsers = filtered;
    this.paginate();
  }

  paginate(): void {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedUsers = this.filteredUsers.slice(start, end);
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.fetchUsers(this.currentPage);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchUsers(this.currentPage);
    }
  }

  onFilterChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.selectedFilter = target.value;
    this.currentPage = 1;

    if (this.selectedFilter === 'preview') {
      this.fetchGuestUsers(); // Fetch guest users if 'preview' is selected
    } else {
      this.filterUsers();
    }
  }

  onSearchChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.searchTerm = target.value;
    this.currentPage = 1;
    this.filterUsers();
  }

  viewUser(id: number) {
    const user = this.users.find(user => user.id === id);
    if (user) {
      const modalRef = this.modalService.open(ViewusersComponent);
      modalRef.componentInstance.user = user;
    }
  }

  editUser(id: number) {
    this.router.navigate(['/edit-user', id]);
  }

  deleteUser(id: number) {
    const modalRef = this.modalService.open(DeleteuserComponent);
    modalRef.componentInstance.userId = id;

    modalRef.result.then((result) => {
      if (result === 'delete') {
        this.users = this.users.filter(user => user.id !== id);
        this.filterUsers();
      }
    }).catch((error) => console.error('Error closing modal:', error));
  }
}
