import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService, ReceiptsResponse, TotalActiveUsersResponse, TotalAppointmentsResponse, TotalCompletedSessionsResponse, TotalUsersResponse } from '../../../services/dashboard.service';
import { FeedbackModalComponent } from '../../../feedback-modal/feedback-modal.component';

interface Feedback {
  id: string;
  user_id?: string;
  user_name?: string;
  rating: number;
  focus_areas: string[];
  message: string;
  created_at: string;
}


@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css'] 
})
export class CardsComponent {
  
  totalUsers: number = 0;
  totalAppointments: number = 0;
  totalActiveUsers: number = 0;
  totalReceipts: number = 0;
  totalCompletedSessions: number = 0;
  stars: number[] = [1, 2, 3, 4, 5];
  totalFeedbackRating: number = 0;
  feedbackList: Feedback[] = [];
  private feedbackModalRef: any;
  user_count: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalPages: number = 1;
  pageNumbers: number[] = [];
  loading: boolean = true;
  paginatedFeedbackList: Feedback[] = [];
  

  @ViewChild('feedbackModal') feedbackModal!: TemplateRef<any>;

  constructor(private dashboardService: DashboardService, private modalService: NgbModal) { }


  ngOnInit(): void {
    console.log('ngOnInit called');
    this.fetchTotalUsers();
    this.fetchUserCount();
    this.fetchTotalAppointments();
    this.getTotalReceipts();
    this.getTotalCompletedSessions();
    this.fetchFeedbackList();
  }

  calculateTotalRating(): void {
    const totalRating = this.feedbackList.reduce((sum, feedback) => sum + feedback.rating, 0);
    const count = this.feedbackList.length;
    this.totalFeedbackRating = count ? Math.round(totalRating / count) : 0;
  }

  openFeedbackModal(): void {
    this.modalService.open(this.feedbackModal, { size: 'lg' });
  }

  openFeedbackList(event: Event): void {
    event.stopPropagation();
    this.openFeedbackModal();
  }
  fetchFeedbackList(): void {
    this.loading = true;
    this.dashboardService.getFeedbackList().subscribe(
      (response: Feedback[]) => {
        this.feedbackList = response;
        this.totalPages = Math.ceil(this.feedbackList.length / this.itemsPerPage);
        this.paginate();
        this.generatePageNumbers(); // Generate page numbers
       
        this.calculateTotalRating();
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching feedback list', error);
        this.loading = false;
      }
    );
  }

  paginate(): void {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedFeedbackList = this.feedbackList.slice(start, end);
  }
  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.paginate();
    }
  }
  generatePageNumbers(): void {
    this.pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  
  fetchTotalUsers(): void {
    this.dashboardService.getTotalUsers().subscribe(
      (response: TotalUsersResponse) => {
        this.totalUsers = response.user_count;
        console.log(response,'users');
      },
      (error) => {
        console.error('Error fetching total users', error);
      }
    );
  }

  fetchUserCount(): void {
    this.dashboardService.getTotalActiveUsers().subscribe(
      (response: TotalActiveUsersResponse) => {
        this.totalActiveUsers = response.active_user_count;
        console.log(response, 'appointments');
      },
      (error) => {
        console.error('Error fetching total appointments', error);
      }
    );
  }

  fetchTotalAppointments(): void {
    this.dashboardService.getTotalAppointments().subscribe(
      (response: TotalAppointmentsResponse) => {
        this.totalAppointments = response.appointments_count;
        console.log(response, 'appointments');
      },
      (error) => {
        console.error('Error fetching total appointments', error);
      }
    );
  }
  
  getTotalCompletedSessions(): void {
    this.dashboardService.getTotalCompletedSessions().subscribe(
      (response: number) => {
        this.totalCompletedSessions = response;
        console.log('Attended Meetings Count:', this.totalCompletedSessions);
      },
      (error) => {
        console.error('Error fetching total completed sessions', error);
      }
    );
  }
  getTotalReceipts(): void {
    this.dashboardService.getTotalReceipts().subscribe(
      (response: ReceiptsResponse) => {
        this.totalReceipts = response.count;
        console.log(response, 'receipts');
      },
      (error) => {
        console.error('Error fetching total receipts', error);
      }
    );
  }
}
