<!-- src/app/edit-user/edit-user.component.html -->
<div class="container mt-4">
  <h2 class="text-center">Edit User</h2>
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="name">Username</label>
      <input id="name" formControlName="name" class="form-control" />
    </div>
    <div class="form-group">
      <label for="identity">Identity</label>
      <select id="identity" formControlName="identity" class="form-control">
        <option value="client">Client</option>
        <option value="therapist">Therapist</option>
      </select>
    </div>
    <div class="form-group" *ngIf="userForm.get('identity')?.value === 'client'">
      <label for="therapistName">Therapist Name</label>
      <input id="therapistName" formControlName="therapistName" class="form-control" />
    </div>
    <div class="form-group">
      <label for="sessions">Number of Sessions</label>
      <input id="sessions" formControlName="sessions" type="number" class="form-control" />
    </div>
    <button type="submit" class="btn btn-primary">Save</button>
  </form>
</div>
