<!-- src/app/user-detail/user-detail.component.html -->
<div class="container mt-4">
  <h5 class="text-center">User Details</h5>
  <div class="card">
    <div class="card-body">
      <p class="card-text"><strong>Name:</strong> {{ user?.name }}</p>
      <p class="card-text"><strong>Identity:</strong> {{ user?.identity }}</p>
      <p *ngIf="user?.identity === 'client'" class="card-text"><strong>Therapist Name:</strong> {{ user?.therapistName }}</p>
      <p class="card-text"><strong>Number of Sessions:</strong> {{ user?.sessions }}</p>
    </div>
  </div>
</div>
