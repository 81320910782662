import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { LoginComponent } from './components/login/login.component';
/* import { CardComponent } from './components/card/card.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
 */import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
//import { environment } from '../environments/environment.prod';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../environments/environment';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { MatDialogModule } from '@angular/material/dialog';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { CardsComponent } from './components/dashboard/cards/cards.component';
import { UserdetailsComponent } from './components/users/userdetails/userdetails.component';
import { EdituserComponent } from './components/users/edituser/edituser.component';
import { LayoutComponent } from './components/layout/layout/layout.component';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { DeleteuserComponent } from './components/users/deleteuser/deleteuser.component';
import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule
import { ViewusersComponent } from './components/users/viewusers/viewusers.component';
import { PreviewUsersComponent } from './components/users/preview-users/preview-users.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { NgbModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ToolsComponent } from './components/tools/tools.component';
import { ActivityComponent } from './components/activity/activity.component';
//import { NgChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Import BrowserAnimationsModule
import { ToastrModule } from 'ngx-toastr';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { ReceiptsComponent } from './components/receipts/receipts.component'; // Import ToastrModule



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    NavbarComponent,
    SidebarComponent,
    CardsComponent,
    UserdetailsComponent,
    EdituserComponent,
    LayoutComponent,
    FeedbackModalComponent,
    DeleteuserComponent,
    ViewusersComponent,
    PreviewUsersComponent,
    BookingsComponent,
    ActivityComponent,
    ToolsComponent,
    BookingDetailsComponent,
    ReceiptsComponent,

    
   /*  CardComponent,
    LineChartComponent,
    BarChartComponent,
    PieChartComponent */
  ],
  imports: [
    BrowserModule,
    NgbModule,
    NgbDatepickerModule,
    MatDialogModule ,
    RouterOutlet,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,
   
    ToastrModule.forRoot({
      positionClass: 'toast-top-right', // Position the toasts at the top-right corner
      timeOut: 3000, // Duration for which the toast is displayed
      closeButton: false, // Show close button on the toast
      progressBar: false, // Show progress bar
      preventDuplicates: true, // Prevent duplicate toasts
      easeTime: 300, // Animation duration
      extendedTimeOut: 1000 // Time for which toast stays open on hover
    })
  ],
  providers: [
    //entryComponents: [UsersComponent],
    provideFirebaseApp(() => initializeApp({"projectId":"xaidi-dashboard-dev","appId":"1:1096108479131:web:c055e9b3c0dcc1bdb46c3e","storageBucket":"xaidi-dashboard-dev.appspot.com","apiKey":"AIzaSyBNn17m4QEUbAovzPeYUOjfMOPcISH9V7E","authDomain":"xaidi-dashboard-dev.firebaseapp.com","messagingSenderId":"1096108479131"})),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
