import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-viewusers',
  templateUrl: './viewusers.component.html',
  styleUrl: './viewusers.component.css'
})
export class ViewusersComponent {
  userId: number | null = null;
  user: any; // Replace with actual User type if available

  // Hardcoded user details for demonstration
  private users = [
    { id: 1, username: 'john_doe', identity: 'client', therapistName: 'Dr. Smith', sessions: 5 },
    { id: 2, username: 'jane_smith', identity: 'therapist', therapistName: '', sessions: 0 },
    { id: 3, username: 'alice_jones', identity: 'client', therapistName: 'Dr. Brown', sessions: 8 },
    { id: 4, username: 'bob_brown', identity: 'therapist', therapistName: '', sessions: 0 },
    { id: 5, username: 'carol_white', identity: 'client', therapistName: 'Dr. Johnson', sessions: 3 },
    { id: 6, username: 'jane_doe', identity: 'client', therapistName: 'Dr. Smith', sessions: 5 },
    { id: 7, username: 'jane_doe',  identity: 'client', therapistName: 'Dr. Smith', sessions: 5 },
    { id: 8, username: 'jane_doe', identity: 'therapist', therapistName: '', sessions: 0 },
    { id: 9, username: 'jane_doe', identity: 'client', therapistName: 'Dr. Smith', sessions: 5 },
    { id: 10, username: 'jane_doe', identity: 'therapist', therapistName: '', sessions: 0 },
  ];

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.userId = +params.get('id')!;
      this.user = this.users.find(user => user.id === this.userId);
    });
  }

}
