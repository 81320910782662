import { Component, OnInit } from '@angular/core';
import { BookingsService } from '../../services/bookings.service';
import { ChartOptions, ChartType } from 'chart.js';
import { ChartDataset } from 'chart.js';
import { Router } from '@angular/router';
import { BookingDetailsComponent } from '../booking-details/booking-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface Duration {
  joined: string;
  left: string;
  time_elapsed_min: number;
  deductedTokens?: number;
}

interface AttendeeDuration {
  attendee: string;
  deductedTokens: boolean;
  durations: Duration[];
}

interface Attendee {
  attendee: string;
  confirmed: boolean;
  setter: boolean;
  attended: boolean;
  disputedReason: string;
}

interface Booking {
  id: string;
  title: string;
  date: string;
  duration: number | null;
  start_time: string;
  end_time: string;
  attendees: Attendee[];
  created_at: string;
  summary: string;
  date_modified: string;
  attendee_durations: AttendeeDuration[];
}

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {
  bookings: Booking[] = [];
  filteredBookings: Booking[] = [];
  paginatedBookings: Booking[] = [];
  searchTerm: string = '';
  selectedFilter: string = '';
  selectedFilter1: string = ''; // For filtering based on date
  currentPage: number = 1;
  itemsPerPage: number = 7;
  errorMessage: string = '';
  isLoading: boolean = true;
  selectedBooking: Booking | null = null; // Add this property
  selectedConfirmationFilter: string = '';
  // Sorting properties
  sortColumn: string = 'date'; // Default sorting column
  sortDirection: 'asc' | 'desc' = 'asc'; // Default sorting direction



  // Date selectors
  startDate: string = '';
  endDate: string = '';

  // Line chart data
  lineChartData: ChartDataset[] = [{ data: [], label: 'Completed Bookings' }];
  lineChartLabels: string[] = [];  // Updated to an array of strings
  lineChartOptions: ChartOptions = { responsive: true };
  lineChartLegend = true;
  lineChartType: ChartType = 'line';


  constructor(
    private bookingsService: BookingsService,
    private router: Router,
    private modalService: NgbModal // Inject NgbModal here
  ) {}
  ngOnInit(): void {
    this.isLoading = true;
    this.fetchBookings();
  }

  updatePaginatedBookings(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    this.paginatedBookings = this.filteredBookings.slice(startIndex, startIndex + this.itemsPerPage);
    console.log('Updated paginated bookings:', this.paginatedBookings);
  }

  searchAndFilter(): void {
    // Convert search term to lowercase for case-insensitive comparison
  const searchTermLower = this.searchTerm.toLowerCase();

  this.filteredBookings = this.bookings.filter(booking => {
    const titleMatch = (booking?.title || '').toLowerCase().includes(searchTermLower);
    const idMatch = (booking?.id || '').toLowerCase().includes(searchTermLower);

    // Filter based on either title or ID match
    return titleMatch || idMatch;
  });
  
    // Apply additional filtering based on confirmation status
    switch (this.selectedFilter) {
      case 'therapist':
        // Show bookings confirmed by therapist only
        this.filteredBookings = this.filteredBookings.filter(booking =>
          booking.attendees.some(att => att.setter && att.confirmed) &&
          booking.attendees.every(att => att.setter || !att.confirmed)
        );
        break;
      case 'client':
        // Show bookings confirmed by client only
        this.filteredBookings = this.filteredBookings.filter(booking =>
          booking.attendees.some(att => !att.setter && att.confirmed) &&
          booking.attendees.every(att => !att.setter || !att.confirmed)
        );
        break;
      case 'both':
        // Show bookings confirmed by both therapist and client
        this.filteredBookings = this.filteredBookings.filter(booking =>
          booking.attendees.some(att => att.setter && att.confirmed) &&
          booking.attendees.some(att => !att.setter && att.confirmed)
        );
        break;
      case 'none':
        // Show bookings unconfirmed by both therapist and client
        this.filteredBookings = this.filteredBookings.filter(booking =>
          booking.attendees.every(att => !att.confirmed)
        );
        break;
      default:
        // No additional filter applied
        break;
    }
  
    this.currentPage = 1;
    this.updatePaginatedBookings();
  }

  fetchBookings(): void {
    this.bookingsService.getBookings().subscribe(
      (data: string) => {  
        console.log('Raw API data:', data);
        try {
          const jsonData = JSON.parse(data);
          this.bookings = JSON.parse(jsonData);
          console.log(this.bookings);
        } catch (error) {
          console.error('Error parsing JSON:', error);
          return; 
        }
  
        console.log('Fetched bookings:', this.bookings); 
        this.filteredBookings = [...this.bookings];
        console.log('Filtered bookings:', this.filteredBookings); 
        this.updatePaginatedBookings(); 
      },
      error => {
        this.errorMessage = 'Error fetching bookings. Please try again later.';
        console.error('Error fetching bookings:', error);
      }
    );
  }

  sortBookings(): void {
    this.filteredBookings.sort((a, b) => {
      const compareA = this.getSortValue(a);
      const compareB = this.getSortValue(b);
  
      if (compareA < compareB) {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      if (compareA > compareB) {
        return this.sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  
    this.updatePaginatedBookings(); // Update the displayed bookings after sorting
  }
  
  getSortValue(booking: Booking): any {
    switch (this.sortColumn) {
      case 'title':
        return booking.title?.toLowerCase() || '';
      case 'clientName':
        return this.getClientName(booking).toLowerCase();
      case 'date':
        return new Date(booking.date).getTime();
      case 'duration':
        return booking.duration || 0;
      case 'id':
        return booking.id.toLowerCase();
      case 'therapist':
        return this.getTherapistConfirmation(booking).toLowerCase();
      case 'client':
        return this.getClientConfirmation(booking).toLowerCase();
      case 'feedback':
        return this.getFeedback(booking).toLowerCase();
      default:
        return ''; // Fallback if the column is not recognized
    }
  }
  
  changeSortColumn(column: string): void {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
  
    this.sortBookings();
  }
  
 
  
    
  updateGraphData(): void {
    // Define the type for bookingsByDate
    const bookingsByDate: { [key: string]: number } = {};  // Explicitly type the object
  
    const filteredBookings = this.bookings.filter(booking => {
      const bookingDate = new Date(booking.date);
      const startDate = this.startDate ? new Date(this.startDate) : null;
      const endDate = this.endDate ? new Date(this.endDate) : null;
  
      const inRange = (!startDate || bookingDate >= startDate) && (!endDate || bookingDate <= endDate);
  
      const therapistConfirmed = booking.attendees.some(attendee => attendee.setter && attendee.confirmed);
      const clientConfirmed = booking.attendees.some(attendee => !attendee.setter && attendee.confirmed);
  
      return inRange && therapistConfirmed && clientConfirmed;
    });
  
    // Count the number of completed bookings per date
    filteredBookings.forEach(booking => {
      const date = booking.date.split('T')[0];  // Extract the date part
      if (bookingsByDate[date]) {
        bookingsByDate[date] += 1;
      } else {
        bookingsByDate[date] = 1;
      }
    });
  
    // Update the chart labels and data
    this.lineChartLabels = Object.keys(bookingsByDate);
    this.lineChartData[0].data = Object.values(bookingsByDate);
  }
  

  viewDetails(booking: Booking): void {
    const modalRef = this.modalService.open(BookingDetailsComponent, {
      size: 'lg' // Adjust size as needed
    });
    modalRef.componentInstance.booking = booking;
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedBookings();
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages()) {
      this.currentPage++;
      this.updatePaginatedBookings();
    }
  }

  totalPages(): number {
    return Math.ceil(this.filteredBookings.length / this.itemsPerPage);
  }

  // New helper methods
  getClientName(booking: Booking): string {
    const client = booking.attendees.find(att => !att.setter);
    return client ? client.attendee : 'N/A';
  }

  getTherapistConfirmation(booking: Booking): string {
    const therapist = booking.attendees.find(att => att.setter);
    return therapist ? (therapist.confirmed ? 'Confirmed' : 'Not Confirmed') : 'N/A';
  }

  getClientConfirmation(booking: Booking): string {
    const client = booking.attendees.find(att => !att.setter);
    return client ? (client.confirmed ? 'Confirmed' : 'Not Confirmed') : 'N/A';
  }
  // New helper method to determine feedback
getFeedback(booking: Booking): string {
  const therapist = booking.attendees.find(att => att.setter);
  const client = booking.attendees.find(att => !att.setter);

  if (therapist && client) {
    const bothConfirmed = therapist.confirmed && client.confirmed;
    const bothAttended = therapist.attended && client.attended;
    const disputed = therapist.attended === false || client.attended === false;

    if (bothConfirmed || bothAttended && !booking.attendee_durations.some(d => d.durations.some(duration => duration.deductedTokens))) {
      return 'Successful';
    } else if (!bothConfirmed) {
      return 'Unsuccessful';
    } else if (disputed && booking.attendees.some(att => att.disputedReason !== undefined)) {
      return 'Disputed';
    }
  }
  return 'N/A';
}

  getDuration(booking: Booking): string {
    if (booking.start_time && booking.end_time) {
      const startTime = new Date(booking.start_time).getTime();
      const endTime = new Date(booking.end_time).getTime();
      const durationInMinutes = (endTime - startTime) / (1000 * 60); // Duration in minutes
  
      const hours = Math.floor(durationInMinutes / 60); // Calculate hours
      const minutes = Math.floor(durationInMinutes % 60); // Calculate remaining minutes
  
      if (hours > 0 && minutes > 0) {
        return `${hours} hr ${minutes} min`;
      } else if (hours > 0) {
        return `${hours} hr`;
      } else if (minutes > 0) {
        return `${minutes} min`;
      } else {
        return 'N/A';
      }
    }
    return 'N/A';
  }
  
  
}
